import audioFile from './sample.wav';
import * as Tone from "tone";
import './App.css';

const synth = new Tone.MembraneSynth().toDestination();
const player = new Tone.Player(audioFile).toDestination();
player.loop = true;

let sampleState = false;

function playSynth() {
  synth.triggerAttackRelease("D4","1n");
}

function playSample() {
  sampleState?player.stop():player.start();
  sampleState = !sampleState;
  
}



function App() {
  return (
    <div id='wrapper'>
      <button id="button" onClick={playSynth}>click me</button>
      <button id="buttons" onClick={playSample}>Sample</button>      
    </div>
  );
}

export default App;
